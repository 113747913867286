/* Aptos Black */
@font-face {
    font-family: "Aptos";
    src: url("/fonts/Aptos/Aptos-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
  }
  
  /* Aptos Black Italic */
  @font-face {
    font-family: "Aptos";
    src: url("/fonts/Aptos/Aptos-Black-Italic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
  }
  
  /* Aptos Bold */
  @font-face {
    font-family: "Aptos";
    src: url("/fonts/Aptos/Aptos-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }
  
  /* Aptos Bold Italic */
  @font-face {
    font-family: "Aptos";
    src: url("/fonts/Aptos/Aptos-Bold-Italic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
  }
  
  /* Aptos Regular */
  @font-face {
    font-family: "Aptos";
    src: url("/fonts/Aptos/Aptos.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  
  /* Aptos Italic */
  @font-face {
    font-family: "Aptos";
    src: url("/fonts/Aptos/Aptos-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
  }
  
  /* Aptos Light */
  @font-face {
    font-family: "Aptos";
    src: url("/fonts/Aptos/Aptos-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }
  
  /* Aptos Light Italic */
  @font-face {
    font-family: "Aptos";
    src: url("/fonts/Aptos/Aptos-Light-Italic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
  }